.container {
	width: 100%;
	min-height: calc(100vh - 0px);
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	background-color: var(--primary-color);
	padding-top: 72px;
}

.header {
	display: flex;
	position: fixed;
	justify-content: space-between;
	align-items: center;
	top: 0;
	width: 100%;
	padding: 8px 20px 8px 12px;
	color: var(--text-color);
	z-index: 20;
	background: linear-gradient(90.05deg, #363636 -23.24%, rgba(54, 54, 54, 0) 60.88%);
}

.logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: var(--text-color);
	text-decoration: none;
	padding: 0;
	margin: 0;
}

.title {
	font-size: 19px;
	line-height: 28px;
	font-weight: 700;
	vertical-align: top;
	text-transform: uppercase;
}

.title_semi {
	font-size: 19px;
	line-height: 28px;
	font-weight: 600;
	vertical-align: top;
	text-transform: uppercase;
}

.img_wrapper {
	padding: 17px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #434343;
	border-radius: 50%;
	line-height: 1;
	margin-right: 20px;
}

.button_top {
	border: none;
	background-color: #1E1E1E;
	border-radius: 1000px;
	padding: 5px 30px 5px 6px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.button_top:hover {
	background: #393939;
}

.button_top:hover > .img_wrapper {
	background: #FFFFFF;
}

.button_top:hover > .img_wrapper > .arrow_back {
  filter: invert(1);
}

.button_top:active {
	background: #1E1E1E;
}

.button_top:active > .img_wrapper {
	background: #434343;
}


.button_top:active > .img_wrapper > .arrow_back {
  filter: invert(0);
}

.button_top_text {
	font-weight: 400;
	font-size: 12px;
	line-height: 100%;
	color: #FFFFFF;
}


.arrow_back {
	transform: rotate(-180deg);
}

.wrapper {
	width: 1160px;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 90px;
}

.bigTitle {
	margin: 0;
	padding: 0;
	font-weight: 700;
	font-size: 64px;
	color: #FFFFFF;
	margin-bottom: 50px;
}

.green {
	color: #BAF729;
	text-transform: uppercase;
}

.formWrapper {
	display: flex;
	width: 50%;
	justify-content: space-between;
	gap: 20px;
}

.rowsWrapper {
	display: flex;
	width: fit-content;
	justify-content: space-between;
	flex-direction: column;
	margin-top: 40px;
}

.rowsHeader {
	display: block;
	width: 100%;
	padding: 10px 20px;
	border: 1px solid #2A2A2A;
	color: #FFFFFF;
	font-weight: bold;
}

.row {
	display: block;
	width: 100%;
	padding: 10px 40px 10px 20px;
	border: 1px solid #2A2A2A;
	border-top: none;
	color: #BFBEBA;
	text-decoration: none;
	transition: all 300ms;
}

.row:hover {
	color: #BAF729;
	font-size: 16px;
}

.formButton {
	width: 30%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	color: #000000;
	font-size: 14px;
	font-weight: bold;
	border: none;
	background-color: #BAF729;
	text-transform: uppercase;
	cursor: pointer;
	transition: background-color 300ms;
}

.formButton:hover {
	background-color: #fff;
}

.formButton:active {
	background-color: #BAF729;
}

.formButton:disabled {
	cursor: not-allowed;
	background-color: #474747;
	color: #BAF729;
}

.formInput {
	width: calc(70% - 20px);
	padding: 15px 40px;
	border-radius: 100px;
	background-color: #2A2A2A;
	border: none;
	color: #FFFFFF;
	outline: none;
	font-size: 14px;
}

.formInput:focus {
	outline: 1px solid #BAF729;
}

.formInput:disabled {
	cursor: not-allowed;
	background-color: #474747;
}

.tx {
	display: flex;
	width: 50%;
	justify-content: center;
	margin-top: 20px;
	padding: 10px 20px;
	background-color: #2A2A2A;
	border-radius: 10000px;
	flex-direction: column;
}

.tx_info {
	color: #FFFFFF;
	margin-right: 20px;
	font-size: 20px;
}

.tx_link {
	color: #BAF729;
	text-underline: none;
	text-decoration: none;
	font-weight: bold;
	margin: 0;
	padding: 0;
	transition: color 300ms;
	font-size: 20px;
}

.tx_link:hover {
	color: #FFFFFF;
}

.tx_link:active {
	color: #BAF729;
}

.tx_error {
	display: flex;
	width: 50%;
	padding: 10px 20px;
	background-color: #2A2A2A;
	margin-top: 10px;
	color: #f7293a;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	border-radius: 100px;
}

.tx_error:last-child {
	margin: 0;
}