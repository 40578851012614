.container {
	width: 100%;
	background-color: var(--primary-color);
	height: calc(100vh - 0px);
	display: flex;
	justify-content: center;
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	width: 90vw;
	padding-top: 50px;
	padding-bottom: 80px;
}

.title {
	font-weight: 700;
	font-size: 32px;
	line-height: 34px;
	color: #FFFFFF;
	margin: 0;
}

.green {
	color: #BAF729;
}

.pc_wrapper {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

.mobile_wrapper {
	display: flex;
	justify-content: center;
}

.mobile_image {
	position: relative;
	top: -50px;
	left: 30px;
}