@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&display=swap');

* {
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	margin: 0;
	overflow: hidden;
}

.MuiPaginationItem-root {
	color: var(--text-color) !important;
	font-family: 'Montserrat', sans-serif !important;
}

.MuiPaginationItem-root.Mui-selected  {
	background-color: #BAF729 !important;
	font-weight: bold;
	color: #272727 !important;
}
