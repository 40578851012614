.container {
	display: flex;
	flex-direction: column;
	padding: 13px 13px 34px 13px;
	border-radius: 20px;
	background-color: #272727;
	width: fit-content;
	align-items: center;
	z-index: 10;
}

.img {
	width: 235px;
	height: 235px;
	border-radius: 20px;
	margin-bottom: 16px;
}

.skeleton {
	width: 235px;
	height: 235px;
	border-radius: 20px !important;
}

.text {
	color: var(--text-color);
	font-size: 26px;
	margin-bottom: 20px;
}

.boost {
	width: 175px;
	height: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	color: #000000;
	font-size: 12px;
	font-weight: bold;
	border: none;
	background-color: #BAF729;
	text-transform: uppercase;
	cursor: pointer;
	transition: background-color 300ms;
}

.boosted {
	width: 175px;
	height: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	color: #BAF729;
	font-size: 12px;
	font-weight: bold;
	border: none;
	background-color: rgba(255, 255, 255, .1);
	text-transform: uppercase;
	cursor: not-allowed;
	transition: background-color 300ms;
}

.boost:hover {
	background-color: #fff;
}

.boost:active {
	background-color: #BAF729;
}

.boost:disabled {
	color: #BAF729;
	background-color: rgba(255, 255, 255, .1);
	cursor: not-allowed;
}

.apple {
	margin-right: 12px;
	position: relative;
	top: -1px;
}
