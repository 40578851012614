.main {
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 50px;
	flex-direction: column;
	position: relative;
	z-index: 20;
}

.titleWrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 40;
}

.title {
	position: relative;
	font-size: 64px;
	font-weight: 700;
	line-height: 72px;
	letter-spacing: -2px;
	color: #F0EAD7;
	text-align: center;
	margin: 0;
	margin-bottom: 30px;
	padding: 0;
	z-index: 2;
}

.green_text {
	font-size: 64px;
	font-weight: 700;
	line-height: 72px;
	letter-spacing: -2px;
	color: #BAF729;
}

.exclamation {
	display: inline-block;
	font-size: 74px;
	font-weight: 700;
	line-height: 72px;
	letter-spacing: -2px;
	color: #BAF729;
	transform: rotate(9deg) translateX(10px);
}

.geometry {
	position: absolute;
	left: 72px;
	top: -32px;
}

.info {
	font-weight: 400;
	font-size: 22px;
	line-height: 126%;
	color: #BFBEBA;
	text-align: center;
	padding: 0;
	margin: 0;
	z-index: 40;
}

.cloud_big {
	position: absolute;
	top: 42px;
	left: 0;
	z-index: 0;
}

.cloud_mid {
	position: absolute;
	top: 236px;
	right: 40px;
	z-index: 0;
}

.cloud_small {
	position: absolute;
	top: 360px;
	left: 150px;
	z-index: 0;
}

.planet {
	position: relative;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	overflow: hidden;
	height: calc(100vh - 300px);
	min-height: 32vw;
}

.planet_background {
	width: 100%;
}

.worms {
	width: 50%;
	display: flex;
	justify-content: center;
	position: absolute;
	height: 40vh;
	bottom: 0;
}

.first_worm {
	position: absolute;
	bottom: 50%;
	left: 31%;
}

.second_worm {
	position: absolute;
	bottom: -6%;
	left: 34.5%;
}

.third_worm {
	position: absolute;
	bottom: 10%;
	left: 52%;
}

.fourth_worm {
	position: absolute;
	bottom: 40%;
	left: 59%;
}

.first_worm_image {
	width: 6vw;
}

.second_worm_image {
	width: 15vw;
	max-width: 350px;
}

.third_worm_image {
	width: 12vw;
	max-width: 350px;
}

.fourth_worm_image {
	width: 13vw;
	max-width: 350px;
}

.purple_cloud {
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	transform: translate(-30%, -85%);
}

.purple_cloud_image {
	width: 8vw;
	max-width: 150px;
}

.red_cloud {
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	transform: translate(63%, -90%);
}

.red_cloud_image {
	width: 18vw;
	max-width: 150px;
}

.blue_cloud {
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	transform: translate(10%, -95%);
}

.blue_cloud_image {
	width: 15vw;
	max-width: 150px;
}

.yellow_cloud {
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	transform: translate(80%, -22%);
}

.yellow_cloud_image {
	width: 16vw;
	max-width: 150px;
}

.worm_text {
	font-size: 12px;
	line-height: 16px;
	font-weight: bold;
	color: #000000;
	display: inline-block;
	text-align: center;
	position: absolute;
	left: 50%;
}

.worm_text_white {
	font-size: 12px;
	line-height: 16px;
	font-weight: bold;
	color: #ffffff;
	display: inline-block;
	text-align: center;
	position: absolute;
	left: 50%;
}

.purple_cloud_text {
	transform: rotate(-10deg) translate(-50%, -40%);
	font-size: 90%;
}

.red_cloud_text {
	transform: rotate(4deg) translate(-50%, -20%);
	font-size: 150%;
	white-space: nowrap;
}

.blue_cloud_text {
	transform: rotate(-7deg) translate(-50%, -30%);
	font-size: 130%;
}

.yellow_cloud_text {
	transform: rotate(10deg) translate(-50%, 0);
	font-size: 110%;
	white-space: nowrap;
}
