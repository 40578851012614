.container {
	width: 100%;
	min-height: calc(100vh - 0px);
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	background-color: var(--primary-color);
	padding-top: 72px;
}

.header {
	display: flex;
	position: fixed;
	justify-content: space-between;
	align-items: center;
	top: 0;
	width: 100%;
	padding: 8px 20px 8px 12px;
	color: var(--text-color);
	z-index: 20;
	background: linear-gradient(90.05deg, #363636 -23.24%, rgba(54, 54, 54, 0) 60.88%);
}

.subHeader {
	padding: 78px 0 100px 0;
	width: 100%;
	background: linear-gradient(90.05deg, #363636 -23.24%, rgba(54, 54, 54, 0) 60.88%);
	display: flex;
	justify-content: center;
	align-items: center;
}

.bigTitle {
	font-size: 64px;
	font-weight: 700;
	color: #F0EAD7;
	margin: 0;
	padding: 0;
}

.bigTitle_green {
	color: #BAF729;
}

.subHeader_wrapper {
	width: 1160px;
	display: flex;
	align-items: center;
}

.logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: var(--text-color);
	text-decoration: none;
	padding: 0;
	margin: 0;
}

.title {
	font-size: 19px;
	line-height: 28px;
	font-weight: 700;
	vertical-align: top;
	text-transform: uppercase;
}

.title_semi {
	font-size: 19px;
	line-height: 28px;
	font-weight: 600;
	vertical-align: top;
	text-transform: uppercase;
}

.geometry_small {
	position: relative;
	left: 95px;
}

.wrapper {
	width: 1160px;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 90px;
}

.first_step {
	width: 100%;
	flex-direction: column;
	position: relative;
	margin-bottom: 53px;
}

.blue_cloud_text {
	display: inline-block;
	position: absolute;
	color: #ffffff;
	left: 25px;
	top: 20px;
	font-weight: 900;
	font-size: 38px;
	transform: rotate(-5.3deg);
}

.first_step_info {
	margin-top: 20px;
	padding: 34px 42px;
	background: #2A2A2A;
	border-radius: 42px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.first_step_info_text {
	font-size: 28px;
	line-height: 34px;
	color: #ffffff;
}

.second_step {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 50px;
}

.second_step_text {
	position: relative;
	padding-top: 100px;
}

.yellow_cloud_text {
	display: inline-block;
	position: absolute;
	color: #000;
	left: 25px;
	top: 125px;
	font-weight: 900;
	font-size: 38px;
	transform: rotate(3.39deg);
}

.third_step {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 50px;
}

.third_step_text {
	position: relative;
	padding-top: 60px;
}

.white_cloud_text {
	display: inline-block;
	position: absolute;
	color: #000;
	left: 35px;
	top: 115px;
	font-weight: 900;
	font-size: 38px;
	transform: rotate(-6.25deg);
}

.white_cloud_info {
	margin-top: 6px;
	display: block;
	font-weight: 400;
	font-size: 22px;
	line-height: 140%;
	color: #BFBEBA;
	margin-bottom: 35px;
}

.image_wrapper {
	padding: 21px 20px;
	background: #2A2A2A;
	border-radius: 67px;
	position: relative;
}

.fourth_step {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 50px;
}

.fourth_cloud {
	position: absolute;
	left: -30%;
	top: 55%;
	transform: scale(-1, 1) rotate(6deg);
}

.semibold {
	font-weight: 600;
}

.yellow_cloud_info {
	color: #ffffff;
	font-weight: bold;
	font-size: 28px;
	padding: 0;
	margin: 0;
	margin-top: 20px;
}


.cloud_mid {
	position: absolute;
	right: -50px;
	top: 350px;
}

.cloud_big {
	position: absolute;
	top: 1120px;
	left: -50px;
}

.second_wrapper {
	background-image: url("../../common/first_backgroud_guide.svg");
	background-size: cover;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 75px;
	padding-bottom: 61px;
}

.metamask_first_step {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 40px;
}

.metamask_text {
	font-weight: 400;
	font-size: 28px;
	line-height: 42px;
	color: #ffffff;
	display: inline-flex;
	align-items: center;
}

.metamask_text_column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.metamask_subtext {
	font-weight: 400;
	font-size: 22px;
	line-height: 140%;
	color: #BFBEBA;
	margin-top: 16px;
}

.text_wrapper {
	display: flex;
	flex-direction: column;
	margin-left: 85px;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
}

.orange_text {
	padding: 3px 10px;
	background: #E98667;
	border-radius: 50px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: #000000;
	font-size: 20px;
	line-height: 1;
	font-weight: 900;
}

.yellow_text {
	padding: 5px 10px;
	background: #F7D74B;
	border-radius: 50px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: #000000;
	font-size: 20px;
	line-height: 1;
	font-weight: 900;
}

.network_info {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: flex-start;
}

.info_row {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 20px;
}

.info_title {
	font-weight: 400;
	font-size: 22px;
	line-height: 225%;
	color: #BFBEBA;
}

.info_text {
	font-weight: 400;
	font-size: 22px;
	line-height: 225%;
	text-align: right;
	color: #FFFFFF;
}

.fifth_step_info {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.fifth_step_text {
	font-weight: 400;
	font-size: 28px;
	line-height: 34px;
	color: #FFFFFF;
	margin-top: 20px;
}

.mint_info {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 115px;
}

.small_title {
	font-weight: 700;
	font-size: 63.29px;
	line-height: 72px;
	margin: 0;
	padding: 0;
	color: #FFFFFF;
	text-align: left;
	width: 100%;
	margin-bottom: 58px;
}

.info_row:last-child {
	margin-bottom: 0;
}

.mint_step {
	padding: 29px 0 42px 40px;
	display: flex;
	flex-direction: column;
	background: #2A2A2A;
	border-radius: 42px;
	width: 100%;
	margin-bottom: 20px;
}

.mint_step:last-child {
	margin-bottom: 0;
}

.mint_first_step_title {
	font-weight: 900;
	font-size: 37.7879px;
	line-height: 43px;
	color: #6DCBFF;
	margin: 0;
	padding: 0;
	margin-bottom: 20px;
}

.mint_second_step_title {
	font-weight: 900;
	font-size: 37.7879px;
	line-height: 43px;
	color: #F7D74B;
	margin: 0;
	padding: 0;
	margin-bottom: 20px;
}

.mint_third_step_title {
	font-weight: 900;
	font-size: 37.7879px;
	line-height: 43px;
	color: #FFFFFF;
	margin: 0;
	padding: 0;
	margin-bottom: 20px;
}

.mint_fourth_step_title {
		font-weight: 900;
	font-size: 37.7879px;
	line-height: 43px;
	color: #E98667;
	margin: 0;
	padding: 0;
	margin-bottom: 20px;
}

.mint_list {
	margin: 0;
	padding-left: 20px;
}

.mint_item {
	font-weight: 400;
	font-size: 24px;
	line-height: 34px;
	color: #FFFFFF;
}

.mint_item_margin {
	margin-bottom: 24px;
}

.mint_text {
	font-weight: 400;
	font-size: 22px;
	line-height: 131%;
	color: #BFBEBA;
	margin: 0;
	padding: 0;
	margin-top: 11px;
}

.third_wrapper {
	width: 1160px;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 90px;
	padding-bottom: 165px;
}

.fourth_wrapper {
	background-image: url("../../common/second_background_guide.svg");
	background-size: cover;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 80px;
	position: relative;
	padding-bottom: 145px;
}

.steps {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 150px;
}

.upgrade_first_step {
	height: 276px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 25px;
	padding-right: 72px;
	background: #272727;
	border-radius: 42px;
	position: relative;
	margin-bottom: 90px;
}

.upgrade_second_step {
	height: 365px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 25px;
	padding-right: 72px;
	padding-top: 80px;
	background: #272727;
	border-radius: 42px;
	position: relative;
	margin-bottom: 90px;
}

.upgrade_third_step {
	height: 184px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 25px;
	padding-right: 72px;
	padding-top: 50px;
	background: #272727;
	border-radius: 42px;
	position: relative;
	margin-bottom: 90px;
}

.upgrade_fourth_step {
	height: 342px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 25px;
	padding-right: 72px;
	padding-top: 50px;
	background: #272727;
	border-radius: 42px;
	position: relative;
}

.upgrade_blue_cloud {
	position: absolute;
	top: 0;
	left: 30px;
	transform: translateY(-50%);
}

.worm {
	position: absolute;
	right: 80px;
	top: 160px;
}

.footer {
	display: flex;
	width: 100%;
	color: #000000;
	padding: 17px 23px;
	justify-content: space-between;
}

.img_wrapper {
	padding: 17px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #434343;
	border-radius: 50%;
	line-height: 1;
	margin-right: 20px;
}

.button_top {
	border: none;
	background-color: #1E1E1E;
	border-radius: 1000px;
	padding: 5px 30px 5px 6px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.button_top:hover {
	background: #393939;
}

.button_top:hover > .img_wrapper {
	background: #FFFFFF;
}

.button_top:hover > .img_wrapper > .arrow_top {
  filter: invert(1);
}

.button_top:hover > .img_wrapper > .arrow_back {
  filter: invert(1);
}

.button_top:active {
	background: #1E1E1E;
}

.button_top:active > .img_wrapper {
	background: #434343;
}

.button_top:active > .img_wrapper > .arrow_top {
  filter: invert(0);
}


.button_top:active > .img_wrapper > .arrow_back {
  filter: invert(0);
}

.button_top_text {
	font-weight: 400;
	font-size: 12px;
	line-height: 100%;
	color: #FFFFFF;
}

.arrow_top {
	transform: rotate(-90deg);
}

.arrow_back {
	transform: rotate(-180deg);
}