:root {
	--text-color: #ffffff;
	--primary-color: #0D0D0D;
	--toastify-color-progress-dark: #BAF729 !important;
}

.container {
	background-color: var(--primary-color);
	min-height: calc(100vh - 0px);
	max-height: calc(100vh - 0px);
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 61px;
}

.connectedContainer {
	background-color: var(--primary-color);
	min-height: calc(100vh - 0px);
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 61px;
}


.wrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	height: calc(100vh - 270px - 60px - 2vw);
}

.toast {
	width: 25vw !important;
}

.render_track {
	background: #272727;
	right: 0px;
	width: 5px !important;
	height: 100%;
	border-radius: 10px;
}

.render_track > div {
	background-color: #BAF729 !important;
}

.scrollBars {
	height: 100vh !important;
}