.header {
	display: flex;
	position: fixed;
	justify-content: space-between;
	top: 0;
	width: 100%;
	padding: 9px 9px 0 13px;
	color: var(--text-color);
	background-color: var(--primary-color);
	z-index: 20;
}

.logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: var(--text-color);
	text-decoration: none;
	padding: 0;
	margin: 0;
}

.title {
	font-size: 19px;
	line-height: 28px;
	font-weight: 700;
	vertical-align: top;
	text-transform: uppercase;
}

.title_semi {
	font-size: 19px;
	line-height: 28px;
	font-weight: 600;
	vertical-align: top;
	text-transform: uppercase;
}

.nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header_actions {
	border: none;
	background: transparent;
	padding: 0;
	color: var(--text-color);
	margin-right: 45px;
	cursor: pointer;
	font-size: 16px;
	line-height: 1;
	font-weight: 400;
	text-decoration: none;
	transition: color 300ms;
}

.header_actions:hover {
	color: #BAF729;
}

.header_actions:active {
	color: #88b61e;
}

.claim:hover {
	color: #BAF729;
}

.claim:active {
	color: #88b61e;
}

.header_actions[aria-current="page"] {
	color: #BAF729;
}

.header_actions:disabled {
	cursor: not-allowed;
	color: rgba(255, 255, 255, 0.33);
}

.active_link {
	border: none;
	background: transparent;
	padding: 0;
	color: #BAF729;
	margin-right: 45px;
	cursor: pointer;
	font-size: 16px;
	line-height: 1;
	font-weight: 400;
	text-decoration: none;
}

.claim {
	display: flex;
	align-items: center;
	border: none;
	background: transparent;
	padding: 0;
	color: var(--text-color);
	margin-right: 45px;
	cursor: pointer;
	font-size: 16px;
	line-height: 1;
	font-weight: 400;
}

.claim:disabled {
	cursor: not-allowed;
	color: rgba(255, 255, 255, 0.33);
}

.apples_count {
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 3px 5px;
	background-color: #1E1E1E;
	font-weight: 600;
	color: #BAF729;
	margin-left: 6px;
}

.apples_text {
	margin-left: 3px;
	font-size: 14px;
}

.connect_btn {
	width: 190px;
	height: 52px;
	background-image: url('../../common/button_background.svg');
	background-size: cover;
	border: none;
	border-radius: 58px;
	color: #000000;
	font-weight: 700;
	font-size: 12px;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 400ms;
}

.connect_btn:hover {
	background-image: url('../../common/button_background_hover.svg');
}

.connect_btn:active {
	background-image: url('../../common/button_background.svg');
}

.account {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #1E1E1E;
	border-radius: 1000px;
	padding: 6px 12px 6px 6px;
}

.avatar {
	border-radius: 50%;
	margin-right: 15px;
}

.account_address {
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	color: rgba(255, 255, 255, .4);
	margin-right: 10px;
}

.copy {
	border: none;
	background-color: transparent;
	padding: 0;
	margin: 0;
	cursor: pointer;
}

.copy:hover {
	opacity: .75;
}

.copy:active {
	opacity: .55;
}