.container {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 61px);
	width: 1175px;
	align-items: center;
	padding-top: 60px;
	padding-bottom: 20px;
	position: relative;
}

.info {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	padding-bottom: 60px;
}

.balance {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 190px;
	height: 52px;
	background-image: url("../../common/button_background.svg");
	background-size: cover;
	text-align: center;
	color: #000000;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 12px;
	margin-right: 16px;
}

.applesBalance {
	display: flex;
	width: 190px;
	height: 52px;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	text-transform: uppercase;
	font-weight: bold;
	background-color: #1E1E1E;
	border-radius: 50px;
	font-size: 12px;
	margin-right: 6px;
}

.gold_text {
	color: #F7D74B;
}

.tooltip {
	width: 23px;
	height: 23px;
	background-color: #1E1E1E;
	border-radius: 50%;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
}

.geometry {
	position: absolute;
	right: -50px;
	top: 0;
	z-index: 1;
}

.cloud_mid {
	position: absolute;
	right: -50px;
	top: 90%;
	z-index: 0;
}

.nftsWrapper {
 	display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
	justify-content: space-between;
  align-items: stretch;
	width: calc(100% - 15px);
	z-index: 5;
}

.horizontal_scroll {
	height: 0;
	display: none;
}

.render_track {
	background: #272727;
	right: 0px;
	width: 7px !important;
	height: 100%;
	border-radius: 10px;
}

.render_track > div {
	background-color: #BAF729 !important;
}

.cloud_big {
	position: absolute;
	top: 400px;
	left: -160px;
}

.emptyWrapper {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
}

.emptyTitle {
	margin: 0;
	margin-bottom: 40px;
	color: #ffffff;
	font-size: 48px;
	text-transform: uppercase;
	letter-spacing: 1.2px;
}

.mint {
	width: 190px;
	height: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	color: #000000;
	font-size: 14px;
	font-weight: bold;
	border: none;
	background-color: #BAF729;
	text-transform: uppercase;
	cursor: pointer;
	transition: background-color 300ms;
}

.mint:hover {
	background-color: #fff;
}

.mint:active {
	background-color: #BAF729;
}

.loaderWrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader div {
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.loader div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #BAF729;
  margin: -4px 0 0 -4px;
}
.loader div:nth-child(1) {
  animation-delay: -0.036s;
}
.loader div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.loader div:nth-child(2) {
  animation-delay: -0.072s;
}
.loader div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.loader div:nth-child(3) {
  animation-delay: -0.108s;
}
.loader div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.loader div:nth-child(4) {
  animation-delay: -0.144s;
}
.loader div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.loader div:nth-child(5) {
  animation-delay: -0.18s;
}
.loader div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.loader div:nth-child(6) {
  animation-delay: -0.216s;
}
.loader div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.loader div:nth-child(7) {
  animation-delay: -0.252s;
}
.loader div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.loader div:nth-child(8) {
  animation-delay: -0.288s;
}
.loader div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 0 20px 0;
}